import React, { useState, useEffect } from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import QuoteContainer from './ohpeneer/QuoteContainer'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useContentInProp } from '@/hooks/useContentInProp'
import { useTranslation } from 'gatsby-plugin-react-i18next'
// import data from '@/texts/ohpeneers'
import PubSub from 'pubsub-js'
import { APP_SWITCH_BACKGROUND_MEDIA } from '@/events'
import { graphql } from 'gatsby'

const sample = (array) => array[Math.floor(Math.random() * array.length)]

export const query = graphql`
  query {
    allCmsOhpeneer(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          status
          created_on
          name
          function
          image {
            data {
              url
            }
          }
          quote
          featured
          overview
          changemaker_image {
            data {
              url
            }
          }
        }
      }
    }
  }
`

const OhpeneersPage: React.FC = ({ data }) => {
  const inProp = useContentInProp()
  const [quote, setQuote] = useState(null)
  const { t } = useTranslation()

  const content = {
    pretitle: t(
      'dna.ohpeneers_super',
      'fill copy key: ohpeneers.ohpeneers_super',
    ),
    title: t('dna.ohpeneers_title', 'fill copy key: ohpeneers.ohpeneers_title'),
    text: t('dna.ohpeneers_body', 'fill copy key: ohpeneers.ohpeneers_body'),
    links: [
      {
        link: t('dna.ohpeneers_cta', 'fill copy key: ohpeneers.ohpeneers_cta'),
        url: '/our-dna/sponsor',
        variant: 'all',
      },
    ],
  }

  const right = quote ? <QuoteContainer inProp={inProp} {...quote} /> : null

  useEffect(() => {
    const ohpeneer = sample(
      data.allCmsOhpeneer.edges.filter((x) => !!x.node.changemaker_image),
    )

    setQuote({
      name: ohpeneer.node.name,
      job: ohpeneer.node.function,
      quote: ohpeneer.node.quote,
    })
    // todo: focusPoint
    PubSub.publish(APP_SWITCH_BACKGROUND_MEDIA, {
      src: ohpeneer.node.changemaker_image.data.url,
      focusPoint:
        ohpeneer.node.focusPoint !== undefined
          ? ohpeneer.node.focusPoint
          : { x: 0.5, y: 0.5 },
    })
  }, [])

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[
                right,
                <Text isMobile inProp={inProp} {...content} />,
              ]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...content} inProp={inProp} />
                </Box>
                <Box
                  col={0.6}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  {right}
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default OhpeneersPage
