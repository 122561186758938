import React from 'react'
import Quote from '@/components/Quote'
import { useContentInProp } from '@/hooks/useContentInProp'

interface Props {
  inProp?: boolean
  quote: string
  name: string
  job: string
}

const QuoteContainer: React.FC<Props> = ({ quote, name, job }) => {
  const inProp = useContentInProp()

  return (
    <Quote
      variant="light"
      name={name === '' ? null : name}
      jobTitle={job === '' ? null : job}
      maxWidth="55rem"
      inProp={inProp}
    >
      {quote}
    </Quote>
  )
}

QuoteContainer.propTypes = {}

QuoteContainer.defaultProps = {}

export default QuoteContainer
